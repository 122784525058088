import React from 'react';
import { useTranslation } from 'react-i18next';

function UserRoleLabel({ value = '' }) {
  const [t] = useTranslation('USERS');

  return (
    <>{typeof value === 'string' ? t(`${value.toUpperCase()}`) : value}</>
  );
}

export default UserRoleLabel;
