import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import { ReactComponent as IconCompanies } from '../../images/icn_aziende.svg';
import SearchAddCont from '../../components/SearchAddCont';
import { Card, CardBody } from 'reactstrap';
import { RubricTable, RubricColumn } from '../../components/RubricTable';
import RubricActivitiesBadge from '../../components/RubricActivitiesBadge';
import Footer from '../../components/Footer';
import BurgerMenu from '../../components/BurgerMenu';
import CompanyStatusLabel from './CompanyStatusLabel';

function CompaniesPresentational(props) {
  const {
    companies,
    isLoading = false,
    isAdding = false,
    query = {},
    onFilter,
    onCreateCompany,
    history,
  } = props;

  const [t] = useTranslation('COMPANIES');

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconCompanies fill='#FF6B38' />}
          />

          <SearchAddCont
            isLoading={isAdding}
            onFilter={onFilter}
            query={query}
            onAdd={onCreateCompany}
            addLabel={t('BTN_ADD_COMPANIES')}
            addConfirm={t('ADD_REQUEST')}
          />

          <hr />

          <Card>
            <CardBody>
              <RubricTable
                onItemClick={({ id }) => history.push(`/company-detail/${id}`)}
                items={companies}
                isLoading={isLoading}
                keyword="name"
              >
                <RubricColumn source="name" />
                <RubricColumn source="address" />
                <RubricColumn source="state">
                  <CompanyStatusLabel />
                </RubricColumn>
                <RubricColumn source="activities.toDoCounter" className="text-right">
                  <RubricActivitiesBadge pill />
                </RubricColumn>
              </RubricTable>
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>


      <BurgerMenu />
    </div >
  )
}

export default CompaniesPresentational;
