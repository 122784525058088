import axios from 'axios';
import qs from 'qs';

const fetchDeals = async (params = {}) => {
  const {
    token,
    offset = 0,
    order = 'closing_date',
    sort = 'desc',
    limit = process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT,
    companyName,
    companyId,
    from,
    to,
    search,
    costCenter,
    stage,
  } = params;

  const queryString = { offset, order, sort, limit, companyName, companyId, from, to, search, costCenter, stage };

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/deals?${qs.stringify(queryString)}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fetchDeals;
