import axios from 'axios';

export const createCostCenter = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/cost_centers`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};


export const saveCostCenter = async ({ id, token, data = {} } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/cost_centers/${id}`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};


export const deleteCostCenter = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/cost_centers/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const dealCostCenters = async ({ dealId, token, data } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/deals/${dealId}/cost_centers`,
      headers: {
        Authorization: token,
      },
      data
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
