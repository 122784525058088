import React from 'react';
import './ActivitiesModalToDo.scss'
import moment from 'moment';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ActivityIcon from '../ActivityIcon';

const ActivityItem = ({ activity, setDone, dismiss }) => {
  const [t] = useTranslation();
  const { description = '', type = '', contact_name, date, id, deal_name } = activity || {}

  return (
    <div className="cont-grey">
      <div className="cont-grey-header">
        <div className="row">
          <div className="col-6">
            <h6>
              <ActivityIcon type={type} />
              {t(`COMMON:${type.toUpperCase()}_LOG`)}
            </h6>
          </div>
          <div className="col-6 text-right">
            <h6>{moment(date).format('D MMMM YYYY, HH:mm')}</h6>
          </div>
        </div>
      </div>
      <div className="cont-grey-body">
        <p className={`activity-description short`}>
          <strong>{contact_name || deal_name}</strong>
          <br/>
          Descrizione: {description}
        </p>
      </div>
      <div className="cont-todo-buttons">
        <Button onClick={() => dismiss(id)} className="dismiss"><i className="fas fa-times"></i> {t("COMMON:DISMISS")}</Button>
        <Button onClick={() => setDone(id)} className="done"><i className="fas fa-check"></i> {t("COMMON:DONE")}</Button>
      </div>
    </div>
  );
};


export default ActivityItem
