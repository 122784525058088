import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import './ActivityButtonGroup.scss';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, CustomInput
} from 'reactstrap';
import moment from 'moment';
import plusIcon from '../../images/icn-plus.svg';
import FormErrorMessage from '../FormErrorMessage';
import ActivityType from '../ActivityType';
import ModalConfirm from '../ModalConfirm';
import trashIcon from '../../images/icn-trash.svg';
import editIcon from '../../images/icn-edit.svg';
import DateInput from '../DateInput';

function ActivityModal({ open, toggle, onSubmit, activity, type, onDeleteActivity }, ref) {
  const [t] = useTranslation('ACTIVITIES');
  const { handleSubmit, register, errors, reset, setValue, watch } = useForm();
  const { id } = activity || {}
  const date = watch('date', moment().format('YYYY-MM-DD'))

  useEffect(() => {
    register({ name: 'date' })
  }, [register]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      const { date = moment().format('YYYY-MM-DD HH:mm'), description = '', done = 0 } = activity || {}
      reset({
        date: moment(date).format('YYYY-MM-DD'),
        time: moment(date).format('HH:mm'),
        description,
        done: !!done
      })
    }
  }));

  const prepareData = (values) => {
    const data = Object.assign({}, values)
    data.done = data.done ? 1 : 0
    onSubmit(data)
  }

  return (
    <Modal isOpen={open} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t(id ? 'UPDATE_ACTIVITY' : 'REGISTER_NEW_ACTIVITY')} <br />
        <ActivityType type={type} />
      </ModalHeader>
      <ModalBody>
        <Form inline className="m-b-30" onSubmit={handleSubmit(prepareData)}>
          <FormGroup>
            <Label for="Date">{t('DAY')}:</Label>
            <DateInput
              name="date"
              className="mx-sm-4"
              disablePopup={true}
              value={date}
              onChange={v => setValue('date', v ? v : new Date())}
            />
            <FormErrorMessage errors={errors} name="date" />
          </FormGroup>
          <FormGroup >
            <Label for="Time">{t('HOURS')}:</Label>
            <Input
              type="time"
              name="time"
              className="mx-sm-4"
              defaultValue={moment().format('HH:mm')}
              innerRef={register}
            />
            <FormErrorMessage errors={errors} name="time" />
          </FormGroup>
          <FormGroup >
            <CustomInput
              className="done-checkbox"
              name="done"
              defaultChecked={false}
              type="checkbox"
              id="done"
              innerRef={register}
              label={t("COMMON:SET_AS_DONE")}
            />
            <FormErrorMessage errors={errors} name="done" />
          </FormGroup>

          <FormGroup className="form-description">
            <Label>
              {t('COMMON:DESCRIPTION')}
            </Label>
            <Input
              type="textarea"
              name="description"
              className="mx-sm-4"
              innerRef={register({
                required: t('COMMON:REQUIRED'),
              })}
            />
            <FormErrorMessage errors={errors} name="description" />
          </FormGroup>

          <ModalFooter className="cont-btn-modalform">
            {
              id && typeof onDeleteActivity === 'function' &&
              <ModalConfirm
                text={t('DELETE_REQUEST')}
                onConfirm={onDeleteActivity}
                className="user-delete-button"
              >
                <Button className="btn-trash">
                  <img src={trashIcon} alt="" />
                </Button>
              </ModalConfirm>
            }
            <Button type="submit" color="primary">
              <img src={id ? editIcon : plusIcon} alt="" />
              {t(id ? 'UPDATE_ACTIVITY' : 'ADD_ACTIVITY')}
            </Button>
            <Button color="secondary" onClick={toggle}>{t('COMMON:CANCEL')}</Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default forwardRef(ActivityModal);
