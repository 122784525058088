import React, { cloneElement } from 'react';

function RubricColumn(props) {
  const { className, ...otherProps } = props;
  const { children, value } = otherProps;

  if (children) {
    return (
      <td className={className}>{cloneElement(children, { ...otherProps, children: value })}</td>
    );
  }

  return (
    <td>{value}</td>
  );
}

export default RubricColumn;
