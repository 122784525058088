import React, { useRef, useEffect } from 'react';

export default ({ children, onClose }) => {
  const wrapperRef = useRef(null);

  const blur = (event) => {
    event.preventDefault();
    event.target.blur();
  };

  const handleKeyDownOutside = (event) => {
    switch (event.keyCode) {
      case 9: //Tab
      case 13: //Enter
        onClose({ toSave: true });
        blur(event);
        return;
      case 27: //ESC
        onClose({ toSave: false });
        blur(event);
        return;
      default:
        return;
    }
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClose({ toSave: true });
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownOutside, false);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDownOutside)
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    };
  });

  return (<div ref={wrapperRef}>{children}</div>);
};
