import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useQueryParams, StringParam } from 'use-query-params';
import dashboard from '../../lib/fetch/dashboard';
import { useAuth } from '../../components/Auth';
import DashboardPresentational from './DashboardPresentational';

function Dashboard(props) {
  const [isLoading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const { t } = useTranslation('FORGOT');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    companyName: StringParam,
    from: StringParam,
    to: StringParam,
    stage: StringParam,
    costCenter: StringParam,
  });

  const { companyName, from, to, stage, costCenter } = query;

  useEffect(() => {
    const fetchDashboard = async (query) => {
      setLoading(true);
      const { error, data } = await dashboard(query);

      if (error) {
        setDashboardData({});
        setLoading(false);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setDashboardData(data);
      setLoading(false);
    };

    fetchDashboard({ token, companyName, from, to, stage, costCenter });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName, from, to, stage, costCenter]);

  return (<DashboardPresentational
    query={query}
    onFilter={setQuery}
    dashboardData={dashboardData}
    isLoading={isLoading}
    {...props}
  />);
}

export default Dashboard;
