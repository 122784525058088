import React from 'react';
import { Button } from 'reactstrap';
import plusIcon from '../../images/icn-plus.svg';
import Spinner from './../Spinner';
import './SearchAddCont.scss';
import ModalConfirm from '../ModalConfirm';

function AddButtonWithConfirm({ isLoading, onAdd, addLabel, addConfirm }) {
  return isLoading ?
    <Spinner />
    :
    <ModalConfirm text={addConfirm} onConfirm={onAdd}>
      <Button className="btn-plus">
        <img src={plusIcon} alt="" /> {addLabel}
      </Button>
    </ModalConfirm>;
}

export default AddButtonWithConfirm;
