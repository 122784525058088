import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

function CurrencyField({ value }) {
  return (
    <>{formatCurrency(value)}</>
  );
}

export default CurrencyField;
