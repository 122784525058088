import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import CloseDetector from '../CloseDetector';
import './EditableCurrency.scss';

function EditableCurrency({ value, onSave, allowEmpty = false }) {
  const [editable, setEditable] = useState(false);
  const [taValue, setTaValue] = useState('');

  useEffect(() => setTaValue(value), [value]);

  const onKeyDown = (e) => {
    if (e.keyCode === 27) { // ESC
      onClose({ toSave: false });
      setTaValue(value);
    }
  };

  const onClose = ({ toSave = false }) => {
    if (editable && toSave && typeof onSave === 'function') {
      onSave(allowEmpty ? taValue : (taValue || value));
    }

    setEditable(false);
  };

  return (
    <CloseDetector onClose={onClose}>
      <NumberFormat
        className={`form-control editable-currency${editable ? ' editable' : ''}`}
        onValueChange={({ floatValue = 0 } = {}) => setTaValue(floatValue)}
        autoFocus={false}
        allowNegative={false}
        suffix=" €"
        decimalScale="2"
        fixedDecimalScale={true}
        decimalSeparator=","
        thousandSeparator="."
        value={taValue}
        onKeyDown={onKeyDown}
        onFocus={() => { console.log('onFocus CurrencyInput'); setEditable(true) }}
      />
    </CloseDetector>
  );
}

EditableCurrency.propTypes = {
  value: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
};

export default EditableCurrency;
