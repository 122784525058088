import React from 'react';
import SearchInput from '../SearchInput';
import AddButton from './AddButton';
import AddButtonWithConfirm from './AddButtonWithConfirm';
import './SearchAddCont.scss';

function SearchAddCont({ isLoading, onAdd, addLabel, addConfirm, onFilter, query }) {
  return (
    <div className="row search-add-cont">
      <div className="col-sm-12 col-md-6 col-lg-8">
        <SearchInput onFilter={onFilter} query={query} />
      </div>
      {
        typeof onAdd === 'function' &&
        <div className="col-sm-12 col-md-6 col-lg-4">
          {
            addConfirm ?
              <AddButtonWithConfirm
                isLoading={isLoading}
                onAdd={onAdd}
                addLabel={addLabel}
                addConfirm={addConfirm}
              />
              :
              <AddButton
                isLoading={isLoading}
                onAdd={onAdd}
                addLabel={addLabel}
              />
          }
        </div>
      }
    </div>
  );
}

export default SearchAddCont;
