import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ActivityIcon from '../ActivityIcon';

function CardActivityItem({ activity, openModal }) {
  const [t] = useTranslation('DEAL_DETAIL');
  const [expanded, setExpanded] = useState(false);

  const { description = '', type = '', contact_name, date } = activity || {}

  return (
    <div className="cont-grey">
      <div className="cont-grey-header" onClick={() => openModal(activity)} style={{ cursor: 'pointer' }}>
        <div className="row">
          <div className="col-6">
            <h6>
              <ActivityIcon type={type} />
              {t(`COMMON:${type.toUpperCase()}_LOG`)}
            </h6>
          </div>
          <div className="col-6 text-right">
            <h6>{moment(date).format('D MMMM YYYY, HH:mm')}</h6>
          </div>
        </div>
      </div>
      <div className="cont-grey-body">
        <p className={`activity-description ${expanded ? 'long' : 'short'}`}>
          <b>{contact_name || ''}</b> {description}
        </p>
        {
          !expanded && description.length > 50 &&
          <span onClick={() => setExpanded(true)} className="read-less-more">
            {t('COMMON:READ_MORE')}
          </span>
        }
        {
          expanded && description.length > 50 &&
          <span onClick={() => setExpanded(false)} className="read-less-more">
            {t('COMMON:CLOSE')}
          </span>
        }
      </div>
    </div>
  )
}

export default CardActivityItem
