export const groupByKeyword = (list, keyword = 'name') => {
  if (!Array.isArray(list)) return [];

  const byInitial = [];
  const letters = new Set();

  list
    .filter(item => item && item[keyword] && typeof item[keyword] === 'string')
    .forEach((item) => {
      const name = item[keyword]
      letters.add(name.charAt(0).toUpperCase())
    });

  Array.from(letters)
    .sort((a, b) => a > b ? 1 : -1)
    .forEach(uppercaseLetter => {
      const lowercaseLetter = uppercaseLetter.toLowerCase();
      byInitial.push(
        list.filter((item) => {
          const name = item[keyword]
          return [uppercaseLetter, lowercaseLetter].some(letter => name.startsWith(letter));
        })
      );
    });

  return byInitial;
};

export const getGroupInitial = (group = [], keyword = 'name') => {
  if (!group || !group[0]) return '';

  const name = group[0][keyword];
  if (typeof name !== 'string') return '';

  return name.charAt(0).toUpperCase();
};

export const stripAdmins = list => {
  if (!Array.isArray(list)) return [];

  return list
    .filter(item => item)
    .filter(({ role }) => role !== 'admin');
};
