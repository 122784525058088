import React from 'react';
import { useTranslation } from 'react-i18next';
import './Lang.scss';
import classnames from 'classnames';

function Lang() {
  const [, i18n] = useTranslation();
  const lang = i18n.language

  return (
    <div className="btn-lang">
      <button className={classnames({ active: lang === 'it' })} onClick={() => i18n.changeLanguage('it')}>ITA</button> |
      <button className={classnames({ active: lang === 'en' })} onClick={() => i18n.changeLanguage('en')}>ENG</button>
    </div>
  );
}

export default Lang;
