import React from 'react';
import moment from 'moment';

function DateTimeField({ value, format }) {
  const dateTimeFormat = format || 'DD/MM/YYYY HH:mm';

  return (
    <>{value && moment(value).format(dateTimeFormat)}</>
  );
}

export default DateTimeField;
