import React, { useState, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label,
} from 'reactstrap';
import './SearchModal.scss';

function SearchModal(props) {
  const { children, title, searchLabel, open, toggle, onConfirm, onDelete } = props;

  const [t] = useTranslation('COMMON');
  const [value, setValue] = useState();

  const handleDelete = () => {
    onDelete();
    toggle();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm(value);
    toggle();
  };

  return (
    <Modal className="search-modal" isOpen={open} fade={false} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Form className="m-b-30" onSubmit={handleSubmit}>
          <FormGroup>
            <Label>{searchLabel}</Label>
            {cloneElement(children, { onChange: setValue })}
          </FormGroup>

          <ModalFooter className="cont-btn-modalform">
            <Button onClick={handleDelete} className="dismiss">
              <i className="fas fa-trash"></i> {t("COMMON:DELETE")}
            </Button>
            <Button type="submit" className="done" disabled={!value}>
              <i className="fas fa-check"></i> {t("COMMON:CONFIRM")}
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default SearchModal;
