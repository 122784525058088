import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import { ReactComponent as IconContacts } from '../../images/icn_contatti.svg';
import SearchAddCont from '../../components/SearchAddCont';
import { Card, CardBody } from 'reactstrap';
import { RubricColumn, RubricTable } from '../../components/RubricTable';
import RubricActivitiesBadge from '../../components/RubricActivitiesBadge';
import Footer from '../../components/Footer';
import BurgerMenu from '../../components/BurgerMenu';

function ContactsPresentational(props) {
  const {
    contacts,
    isLoading = false,
    isAdding = false,
    onFilter,
    onCreateContact,
    query,
    history,
  } = props;

  const [t] = useTranslation('CONTACTS');

  return (
    <div className="App">
      <Menu />

      <div className="corpo">
        <section>
          <Header
            title={t('TITLE')}
            icon={<IconContacts fill='#FF6B38' />}
          />

          <SearchAddCont
            isLoading={isAdding}
            onAdd={onCreateContact}
            addLabel={t('BTN_ADD_CONTACTS')}
            addConfirm={t('ADD_REQUEST')}
            onFilter={onFilter}
            query={query}
          />

          <hr />

          <Card>
            <CardBody>
              <RubricTable
                onItemClick={({ id }) => history.push(`/contact-detail/${id}`)}
                isLoading={isLoading}
                items={contacts}
                keyword="last_name"
              >
                <RubricColumn source="last_name" />
                <RubricColumn source="first_name" />
                <RubricColumn source="email" />
                <RubricColumn source="activities.toDoCounter" className="text-right">
                  <RubricActivitiesBadge pill />
                </RubricColumn>
              </RubricTable>
            </CardBody>
          </Card>

        </section>
        <Footer />
      </div>


      <BurgerMenu />
    </div >
  )
}

export default ContactsPresentational;
