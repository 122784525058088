import React, { useEffect, useState } from 'react';
import CompanyDetailPresentational from './CompanyDetailPresentational';
import './CompanyDetail.scss';
import { fetchCompany, saveCompany, deleteCompany } from '../../lib/fetch/company';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useAuth } from '../../components/Auth';
import { useLoading } from '../../components/Loading';
import { fetchContacts } from '../../lib/fetch/contacts';
import fetchDeals from '../../lib/fetch/deals';
import { useQueryParams, NumberParam } from 'use-query-params';

function CompanyDetail(props) {
  const { match, history } = props
  const { params: { id } = {} } = match;
  const [company, setCompany] = useState({});
  const [contacts, setContacts] = useState([]);
  const [t] = useTranslation('COMPANYDETAIL');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading()

  const [isLoading, setIsLoading] = useState(false);
  const [deals, setDeals] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });

  const { offset = 0 } = query

  useEffect(() => {
    const fetch = async (id) => {
      const { error, data } = await fetchCompany({ id, token });

      if (error) {
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }
      setCompany(data);

      const { error: errorContacts, data: dataContacts } = await fetchContacts({ companyId: id, token, limit: 1000000 });
      if (!errorContacts) {
        setContacts(dataContacts)
      }
    }

    Number(id) && fetch(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async (query) => {
      setIsLoading(true);
      const { error, data, headers } = await fetchDeals(query);

      if (error) {
        setIsLoading(false);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setIsLoading(false);
      setDeals(data);
      setTotal(Number(headers['x-total-count']));
    };

    fetch({ token, offset, companyId: id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  const onSave = async (values) => {
    const { error, data } = await saveCompany({ token, id, data: values });
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    setCompany(data)
  }

  const onDelete = async () => {
    showLoading()
    const { error } = await deleteCompany({ token, id });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push('/companies');
    alert.success(t('COMMON:DELETE_SUCCESS'));
  }

  return (
    <CompanyDetailPresentational
      company={company}
      contacts={contacts}
      deals={deals}
      onSave={onSave}
      onDelete={onDelete}
      isLoading={isLoading}
      total={total}
      onFilter={setQuery}
      query={query}
      {...props}
    />
  )
}
export default CompanyDetail;
