import React, { useState, useEffect } from 'react';
import CloseDetector from './../CloseDetector';
import InputEditableLabel from './InputEditableLabel';
import CompaniesAutosuggest from '../CompaniesAutosuggest';
import ContactsAutosuggest from '../ContactsAutosuggest';
import UsersAutosuggest from '../UsersAutosuggest';
import './EditableLabel.scss';

const getComponent = (type) => {
  switch (type) {
    case 'company':
      return CompaniesAutosuggest;
    case 'user':
      return UsersAutosuggest;
    case 'contact':
      return ContactsAutosuggest;
    default:
      return InputEditableLabel;
  }
}

function EditableLabel({ children, type, value, onSave, allowEmpty = false }) {

  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => setInputValue(value), [value])

  const onClose = ({ toSave = false }) => {
    if (editable && toSave && typeof onSave === 'function') {
      onSave(allowEmpty ? inputValue : (inputValue || value));
    }

    setEditable(false);
  }

  const Component = getComponent(type);

  return (
    <CloseDetector onClose={onClose}>
      <span className="editable-label">
        {
          editable
            ?
            (
              <Component
                type={type}
                value={type === 'date' ? inputValue : value}
                onChange={setInputValue}
              />
            )
            :
            (<span onClick={() => setEditable(true)}>
              {children}
            </span>)
        }
      </span>
    </CloseDetector>
  );
}

export default EditableLabel;
