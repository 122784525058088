import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Companies.scss';
import CompaniesPresentational from './CompaniesPresentational';
import { useAuth } from '../../components/Auth';
import { useAlert } from 'react-alert';
import { StringParam, useQueryParams } from 'use-query-params';
import companies from '../../lib/fetch/companies';
import { createCompany } from '../../lib/fetch/company';

function Companies(props) {
  const { history } = props;
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('COMPANIES');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });

  const { search } = query;

  useEffect(() => {
    const fetchCompanies = async (query) => {
      setIsLoading(true);
      const { error, data, headers } = await companies(query);

      if (error) {
        setIsLoading(false);
        setCompaniesData([]);
        setTotal(0);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setIsLoading(false);
      setCompaniesData(data);
      setTotal(Number(headers['x-total-count']));
    };

    fetchCompanies({ token, search, limit: 100000 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onCreateCompany = async () => {
    const defaultCompany = {
      name: t('COMMON:NEW_COMPANY'),
      annual_budget: 0,
      annual_revenue: 0,
      bank_credit: 0,
      type:'client',
    };

    setIsAdding(true);
    const { error, data } = await createCompany({ data: defaultCompany, token });
    setIsAdding(false);

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push(`/company-detail/${data.id}`);
  };

  return (
    <CompaniesPresentational
      companies={companiesData}
      total={total}
      query={query}
      onCreateCompany={onCreateCompany}
      onFilter={setQuery}
      isLoading={isLoading}
      isAdding={isAdding}
      {...props}
    />
  );
}

export default Companies;
