import React from 'react';
import './Footer.scss';
import { version } from './../../../package.json'

function Footer() {

  return (
    <footer>
      <div className="row">
        <div className="col-6">
          Cookie Policy - Privacy Policy
        </div>
        <div className="col-6 text-right">
          Copyright® Palmabit 2019 v.{version}
        </div>
      </div>
    </footer>
  );
}

export default Footer;