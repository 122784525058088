import React, { useEffect, useState } from 'react';
import { fetchContact, deleteContact, saveContact } from '../../lib/fetch/contacts';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useAuth } from '../../components/Auth';
import { useLoading } from '../../components/Loading';
import ContactDetailPresentational from './ContactDetailPresentational';
import { prepareActivity } from '../../lib/helpers/activities';
import createActivity from '../../lib/fetch/createActivity';
import { fetchActivities, saveActivity, deleteActivity } from '../../lib/fetch/activities';

function CompanyDetail(props) {
  const { match, history } = props
  const { params: { id } = {} } = match;
  const [contact, setContact] = useState({});
  const [activities, setActivities] = useState([]);
  const [t] = useTranslation('COMPANYDETAIL');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading()

  useEffect(() => {
    const fetch = async (id) => {
      const { error, data } = await fetchContact({ id, token });

      if (error) {
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }
      setContact(data);
      await fetchListActivities(id);
    }

    Number(id) && fetch(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (values) => {
    const { error, data } = await saveContact({ token, id, data: values });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    setContact(data);
  }

  const onDelete = async () => {
    const { error } = await deleteContact({ token, id });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push('/contacts');
    alert.success(t('COMMON:DELETE_SUCCESS'));
  }

  const onSaveActivity = async (values, type, activityId) => {
    const data = prepareActivity({ activity: values, type, contact_id: id })

    const { error } = activityId ? await saveActivity({ token, data, id: activityId }) : await createActivity({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'));
    fetchListActivities(id)
  }

  const fetchListActivities = async (contactId) => {
    const { error, data } = await fetchActivities({ contactId, token, limit: 10000000 });

    if (error) {
      setActivities([]);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    setActivities(data);
  }

  const onDeleteActivity = async (activityId) => {
    showLoading()
    const { error } = await deleteActivity({ token, id: activityId })
    hideLoading()
    
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    alert.success(t('COMMON:DELETE_SUCCESS'));
    fetchListActivities(id)
  }

  return (
    <ContactDetailPresentational
      contact={contact}
      onSave={onSave}
      onSaveActivity={onSaveActivity}
      activities={activities}
      onDelete={onDelete}
      onDeleteActivity={onDeleteActivity}
      {...props}
    />
  )
}
export default CompanyDetail;
