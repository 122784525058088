import React from 'react';
import { Card, CardBody, } from 'reactstrap';
import { RubricColumn, RubricTable } from '../../components/RubricTable';
import RubricActivitiesBadge from '../../components/RubricActivitiesBadge';

function CompanyContacts({ contacts, history }) {

  return (
    <Card>
      <CardBody>
        <RubricTable
          onItemClick={({ id }) => history.push(`/contact-detail/${id}`)}
          items={contacts}
          keyword="last_name"
        >
          <RubricColumn source="last_name" />
          <RubricColumn source="first_name" />
          <RubricColumn source="email" />
          <RubricColumn source="activities.toDoCounter" className="text-right">
            <RubricActivitiesBadge pill />
          </RubricColumn>
        </RubricTable>
      </CardBody>
    </Card>
  )
}

export default CompanyContacts;
