import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { IS_MOBILE } from '../../lib/helpers/platformDetector';
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';

function DateInput({ name = '', className = '', value, onChange, disablePopup }) {
  const pickerRef = useRef(null);

  const DatepickerInput = (props) => (
    <input type="text" {...props} readOnly />
  );

  return (
    IS_MOBILE ? (
      <Input
        name={name}
        className={className}
        type="date"
        onChange={ev => {
          if (!ev.target.value) return null;
          onChange((new Date(ev.target.value).toISOString()))
        }}
        defaultValue={value ? moment(value).format('YYYY-MM-DD') : ''}
      />
    ) : (
        <DatePicker
          ref={pickerRef}
          selected={value ? new Date(value) : ''}
          onChange={date => onChange(date ? date.toISOString() : undefined)}
          dateFormat="dd/MM/yyyy"
          locale={it}
          withPortal={!disablePopup}
          customInput={<DatepickerInput />}
        />
      )
  );
}

DateInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disablePopup: PropTypes.bool,
};

export default DateInput;
