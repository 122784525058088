export const prepareActivity = ({ activity = {}, type, deal_id, contact_id }) => {

  const { date, time = '' } = activity;
  
  const dateObject = new Date(date);
  const splitTime = time.split(':');
  const hours = parseInt(splitTime[0]) || 0;
  const minutes = parseInt(splitTime[1]) || 0;
  dateObject.setHours(hours);
  dateObject.setMinutes(minutes);
  delete activity.time;

  const data = {
    ...activity,
    date: dateObject.toISOString(),
    type,
  };

  if (deal_id) {
    data.deal_id = deal_id
  }

  if (contact_id) {
    data.contact_id = contact_id
  }

  return data

}