import React from 'react';
import Proptypes from 'prop-types';
import ReactAutosuggest from 'react-autosuggest';
import './Autosuggest.scss';

class Autosuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested({ value }) {
    const { onFetch } = this.props;

    (async () => {
      const suggestions = await onFetch(value);
      this.setState({ suggestions });
    })();
  };

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
      autoFocus: true 
    };

    return (
      <ReactAutosuggest
        suggestions={suggestions}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion}
        inputProps={inputProps}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={(e, { suggestion }) => this.props.onSelect(suggestion)}
      />
    );
  }
}

Autosuggest.propTypes = {
  placeholder: Proptypes.string,
  fieldName: Proptypes.string.isRequired,
  getSuggestionValue: Proptypes.func.isRequired,
  renderSuggestion: Proptypes.func.isRequired,
  onFetch: Proptypes.func.isRequired,
  onSelect: Proptypes.func.isRequired,
};

export default Autosuggest;
