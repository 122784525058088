import React from 'react';
import { useTranslation } from 'react-i18next';

function CompanyStatusLabel({ value = '' }) {
  const [t] = useTranslation('COMPANYDETAIL');

  return (
    <>{typeof value === 'string' ? t(`STATE_${value.replace(' ', '_').toUpperCase()}`) : value}</>
  );
}

export default CompanyStatusLabel;
