import axios from 'axios';
import qs from 'qs';

const fetchDashboard = async ({ token, from, to, companyName, stage, costCenter } = {}) => {
  try {
    const params = { from, to, stage, costCenter }
    if (companyName && companyName.length > 1) {
      params.companyName = companyName
    }
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/dashboard?${qs.stringify(params)}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fetchDashboard;
