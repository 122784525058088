import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';

export default (props) => {
  const { costCentres } = props;
  const [t] = useTranslation();

  return (
    <ResponsiveContainer width="100%" height={400}>
      {
        (costCentres && Array.isArray(costCentres) && costCentres.length > 0) ?
          <PieChart margin={{ bottom: 20 }}>
            <Pie
              data={costCentres}
              dataKey="value"
              name="label"
              payload={costCentres}
            >
              {
                costCentres.map((entry, index) => <Cell key={index} fill={entry.color} />)
              }
            </Pie>
            <Legend payload={costCentres.map((costCenter, index) => ({ value: costCenter.label, type: 'circle', id: index, color: costCenter.color }))} />
          </PieChart>
          :
          <div className="u-center-center">{t('COMMON:NO_DATA')}</div>
      }
    </ResponsiveContainer>
  );
};
