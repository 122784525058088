import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import i18next from 'i18next';
import { Provider as AlertProvider, positions } from 'react-alert';
import { AuthProvider, PrivateRoute, PublicRoute, RoutesWrapper } from './components/Auth';
import AlertTemplate from './components/AlertTemplate';
import { LoadingProvider } from './components/Loading';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Verification from './pages/Verification';
import Dashboard from './pages/Dashboard';
import Info from './pages/Info';
import Companies from './pages/Companies';
import CompanyDetail from './pages/CompanyDetail';
import Contacts from './pages/Contacts';
import ContactDetail from './pages/ContactDetail';
import Deals from './pages/Deals';
import DealDetail from './pages/DealDetail';
import Activities from './pages/Activities';
import Users from './pages/Users';
import Log from './pages/Log';
import PageNotFound from './pages/PageNotFound';
import translations from './translations';
import './App.scss';
import moment from 'moment';
import 'moment/locale/it';
import HeadMeta from './components/HeadMeta';
import ActivitiesModalToDo from './components/ActivitiesModalToDo';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale('it');

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <AlertProvider template={AlertTemplate} position={positions.TOP_RIGHT} timeout={5000}>
          <CookiesProvider>
            <AuthProvider>
              <LoadingProvider>
                <HeadMeta>
                  <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <RoutesWrapper publicRedirect="/" privateRedirect="/dashboard">
                        <PublicRoute exact path="/" component={Login} />
                        <PublicRoute path="/forgot" component={Forgot} />
                        <PublicRoute path="/reset" component={Reset} />
                        <PublicRoute path="/verify" component={Verification} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/companies" component={Companies} />
                        <PrivateRoute path="/company-detail/:id" component={CompanyDetail} />
                        <PrivateRoute path="/contacts" component={Contacts} />
                        <PrivateRoute path="/contact-detail/:id" component={ContactDetail} />
                        <PrivateRoute path="/deals" component={Deals} />
                        <PrivateRoute path="/deal-detail/:id?" component={DealDetail} />
                        <PrivateRoute path="/activities" component={Activities} />
                        <PrivateRoute path="/users" component={Users} />
                        <PrivateRoute path="/log" component={Log} />
                        <PrivateRoute path="/info" component={Info} />
                        <Route component={PageNotFound} />
                      </RoutesWrapper>
                    </QueryParamProvider>
                  </Router>
                  <ActivitiesModalToDo />
                </HeadMeta>
              </LoadingProvider>
            </AuthProvider>
          </CookiesProvider>
        </AlertProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
