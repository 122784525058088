import axios from 'axios';

const reset = async (code, password) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_URL}/reset/${code}?appId=${process.env.REACT_APP_ID}`,
      data: {
        password
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default reset;
