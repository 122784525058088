import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import { ReactComponent as IconDeals } from '../../images/icn_offerte.svg'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
import backIcon from '../../images/icn-back.svg'
import FrozenButton from '../../components/FrozenButton'
import ProcessStep from '../../components/ProcessStep'
import ActivityButtonGroup from '../../components/ActivityButtonGroup'
import CardActivities from '../../components/CardActivities'
import Footer from '../../components/Footer'
import BurgerMenu from '../../components/BurgerMenu'
import { useTranslation } from 'react-i18next'
import Proptypes from 'prop-types';
import { formatDate } from '../../lib/helpers/formatters';
import EditableTextarea from '../../components/EditableTextarea';
import EditableCurrency from '../../components/EditableCurrency';
import ModalConfirm from '../../components/ModalConfirm';
import trashIcon from '../../images/icn-trash.svg';
import Attachments from '../../components/Attachments';
import CostCenters from '../../components/CostCenters';
import DateInput from '../../components/DateInput';
import './DealDetailPresentational.scss';
import SearchModal from '../../components/SearchModal/SearchModal';
import ContactsAutosuggest from '../../components/ContactsAutosuggest';
import CompaniesAutosuggest from '../../components/CompaniesAutosuggest';
import UsersAutosuggest from '../../components/UsersAutosuggest';

function DealDetailPresentational({ history, match, deal, activitiesData, onSave, onSaveActivity, onDeleteActivity, onDelete, getDeal }) {
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);

  const [t] = useTranslation('DEAL_DETAIL');
  const {
    closing_date,
    amount = 0,
    name = '',
    frozen,
    stage,
    date,
    company_name = '',
    contact_name = '',
    user_name = '',
    attachments,
    id,
    cost_centers,
  } = deal;

  const closingDate = formatDate(closing_date) || t('COMMON:NO_DATE');

  return (
    <div className="App">
      <Menu match={match} />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:DEALS')}
            icon={<IconDeals fill='#FF6B38' />}
          />

          <div className="row m-b-30">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <Button className="btn-plus" onClick={() => history.goBack()}>
                <img src={backIcon} alt="" /> {t('COMMON:BTN_BACK')}
              </Button>
            </div>
            <div className="edit-element">
              {/*  <Button className="btn-trash">*/}
              {/*    <img src={editIcon} alt="" />*/}
              {/*  </Button>*/}
              <ModalConfirm text={t('DEAL_DETAIL:DELETE_REQUEST')} onConfirm={onDelete}>
                <Button className="btn-trash">
                  <img src={trashIcon} alt="" />
                </Button>
              </ModalConfirm>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="sales-card">
                <CardHeader>
                  <h3>
                    <EditableCurrency value={amount} onSave={(v) => onSave({ amount: v })} />
                  </h3>
                  <h3>
                    <EditableTextarea value={name} onSave={(v) => onSave({ name: v })} />
                  </h3>
                  <div className="row">
                    <div className="col-12">
                      <p className="closing-date">{t('COMMON:CLOSING')}: <span>{closingDate}</span></p>
                      <FrozenButton frozen={frozen} onSave={onSave} />
                    </div>
                  </div>
                  <ProcessStep stage={stage} onSave={onSave} />
                </CardHeader>
                <CardBody>
                  <ActivityButtonGroup onSave={onSaveActivity} />
                  <div className="cont-info">
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CLOSING_DATE')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <DateInput
                          name="closing_date"
                          value={closing_date}
                          onChange={v => onSave({ closing_date: v })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CREATED_AT')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <DateInput
                          name="date"
                          value={date}
                          onChange={v => onSave({ date: v })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:COMPANY')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="search-value" onClick={() => !openCompanyModal && setOpenCompanyModal(true)}>
                          {company_name ? <b>{company_name}</b> : <i>{t('SELECT_A_COMPANY')}</i>}
                        </p>
                        <SearchModal
                          title={t('MODIFY_A_COMPANY')}
                          searchLabel={t('COMMON:SEARCH')}
                          open={openCompanyModal}
                          toggle={() => setOpenCompanyModal(false)}
                          onConfirm={result => result && result.id && onSave({ company_id: result.id })}
                          onDelete={() => onSave({ company_id: null, company_name: null })}
                        >
                          <CompaniesAutosuggest />
                        </SearchModal>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:CONTACT')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="search-value" onClick={() => !openContactModal && setOpenContactModal(true)}>
                          {contact_name ? <b>{contact_name}</b> : <i>{t('MODIFY_A_CONTACT')}</i>}
                        </p>
                        <SearchModal
                          title={t('MODIFY_A_CONTACT')}
                          searchLabel={t('COMMON:SEARCH')}
                          open={openContactModal}
                          toggle={() => setOpenContactModal(false)}
                          onConfirm={result => result && result.id && onSave({ contact_id: result.id })}
                          onDelete={() => onSave({ contact_id: null, contact_name: null })}
                        >
                          <ContactsAutosuggest />
                        </SearchModal>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>{t('COMMON:USER')}:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className="search-value" onClick={() => !openUserModal && setOpenUserModal(true)}>
                          {user_name ? <b>{user_name}</b> : <i>{t('MODIFY_A_USER')}</i>}
                        </p>
                        <SearchModal
                          title={t('MODIFY_A_USER')}
                          searchLabel={t('COMMON:SEARCH')}
                          open={openUserModal}
                          toggle={() => setOpenUserModal(false)}
                          onConfirm={result => result && result.id && onSave({ user_id: result.id })}
                          onDelete={() => onSave({ user_id: null, user_name: null })}
                        >
                          <UsersAutosuggest />
                        </SearchModal>
                      </div>
                    </div>
                    <CostCenters
                      dealId={id}
                      costCenters={cost_centers}
                      onSuccessCallback={getDeal}
                    />
                    <Attachments
                      attachments={attachments}
                      dealId={id}
                      onSuccessCallback={getDeal}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CardActivities
                activitiesData={activitiesData}
                onSaveActivity={onSaveActivity}
                onDeleteActivity={onDeleteActivity}
              />
            </div>
          </div>

        </section>
        <Footer />
      </div>

      <BurgerMenu />
    </div >
  );
}

DealDetailPresentational.propTypes = {
  activitiesData: Proptypes.arrayOf(Proptypes.shape({
    type: Proptypes.string,
    date: Proptypes.string,
    description: Proptypes.string,
    created_at: Proptypes.string,
    updated_at: Proptypes.string,
    deal_id: Proptypes.number,
    contact_id: Proptypes.number,
  })).isRequired,
  deal: Proptypes.shape({
    name: Proptypes.string,
    stage: Proptypes.string,
    frozen: Proptypes.number,
    amount: Proptypes.number,
    closing_date: Proptypes.string,
    created_at: Proptypes.string,
    updated_at: Proptypes.string,
    company_id: Proptypes.number,
    contact_id: Proptypes.number,
    contact_name: Proptypes.string,
    company_name: Proptypes.string,
  }),
  history: Proptypes.shape({
    goBack: Proptypes.func.isRequired,
  }),
  onSave: Proptypes.func.isRequired,
  onDelete: Proptypes.func.isRequired,
};

export default withRouter(DealDetailPresentational);
