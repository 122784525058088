import React from 'react';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import gif404 from '../../images/404.gif';

function PageNotFound() {
  return (
    <section>
      <div className="container error-page">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="u-center-vertical">
              <h1>404</h1>
              <p>We're sorry, we couldn't find the page you were looking for</p>
              <Link to={'/'} className="btn">BACK TO HOME</Link>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <img src={gif404} alt="" className="img-fluid" />
          </div>
        </div>

      </div>
    </section>
  )
}

export default PageNotFound;
