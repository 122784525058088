import React from 'react';
import { FormText } from 'reactstrap';

function FormErrorMessage({ errors = {}, name = '' }) {

  if (!errors || !errors[name] || !errors[name].message) {
    return null
  }

  return (
    <FormText className="error-message">{errors[name].message}</FormText>
  )
}

export default FormErrorMessage;
