import React from 'react';
import { Button } from 'reactstrap';
import plusIcon from '../../images/icn-plus.svg';
import Spinner from './../Spinner';
import './SearchAddCont.scss';

function AddButton({ isLoading, onAdd, addLabel }) {
  return isLoading ?
    <Spinner />
    :
    <Button className="btn-plus" onClick={onAdd}>
      <img src={plusIcon} alt="" /> {addLabel}
    </Button>;
}

export default AddButton;
