export const COST_CENTERS = [
  {
    name: 'led',
    color: '#6689A1'
  },
  {
    name: 'print',
    color: '#D8DC6A'
  },
  {
    name: 'events',
    color: '#EB8258'
  },
  {
    name: 'stand',
    color: '#995D81'
  }
]