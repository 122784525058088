import React from 'react';
import { Spinner as ReactstrapSpinner } from 'reactstrap';
import './Spinner.scss';

function Spinner() {
  return (
    <div className="spinner-container">
      <ReactstrapSpinner />
    </div>
  );
}

export default Spinner;
