import React from 'react';
import { Input } from 'reactstrap';

function InputEditableLabel({ type, value, onChange }) {
  const handleFocus = (event) => {
    event.target.select();
  }

  return (
    <Input
      type={type}
      onChange={ev => onChange(type === 'number' ? parseFloat(ev.target.value) : ev.target.value)}
      defaultValue={value}
      autoFocus
      onFocus={handleFocus}
    />
  );
}

export default InputEditableLabel;
