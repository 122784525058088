import React from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useAuth } from '../Auth';
import Autosuggest from '../Autosuggest';
import { fetchContacts } from '../../lib/fetch/contacts';

function ContactsAutosuggest({ onChange }) {
  const [t] = useTranslation('COMMON');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const onFetch = async (search) => {
    const { error, data } = await fetchContacts({ search, token });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    return data;
  };

  return (
    <Autosuggest
      placeholder={t('COMMON:SEARCH_CONTACT_PLACEHOLDER')}
      fieldName="name"
      onFetch={onFetch}
      onSelect={onChange}
      getSuggestionValue={({ first_name = '', last_name = '' } = {}) => `${first_name} ${last_name}`}
      renderSuggestion={({ first_name = '', last_name = '' } = {}) => (<span>{`${first_name} ${last_name}`}</span>)}
    />
  );
}

ContactsAutosuggest.propTypes = {
  onChange: Proptypes.func.isRequired,
};

export default ContactsAutosuggest;
