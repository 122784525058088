import React from 'react';
import emailIcon from '../../images/icn-email.svg';
import meetingIcon from '../../images/icn-incontro.svg';
import callIcon from '../../images/icn-chiamata.svg';

const getActivityIcon = type => {
  switch (type) {
    case 'email':
      return emailIcon
    case 'meeting':
      return meetingIcon
    case 'call':
      return callIcon
    default:
      return emailIcon
  }
};

export default ({ type }) => {
  return (
    <img src={getActivityIcon(type)} alt="" className="img-fluid" />
  )
}