import React from 'react';
import Proptypes from 'prop-types';

import './BalanceCont.scss';

function BalanceCont(props) {

  return (
    <div className="balance-cont">
      <h5>
        {props.title}
      </h5>
      <hr />
      <p>
        {props.value}<br />
        <i>{props.unit}</i>
      </p>
    </div>
  );
}

BalanceCont.propTypes = {
  title: Proptypes.string.isRequired,
  value: Proptypes.number.isRequired,
  unit: Proptypes.string.isRequired,
};
export default BalanceCont;