import React from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ProcessStep.scss';
import { ALL_STEPS } from './steps';

function ProcessStep({ stage, onSave }) {
  const [t] = useTranslation('PROCESS_STEP');
  const stageNumber = ALL_STEPS.indexOf(stage);

  return (
    <div>
      <div className="root">
        <div className="container-processbar">
          <ul className="progressbar">
            {
              ALL_STEPS.map((step, index) => (
                <li key={index} className={stageNumber >= index ? 'active' : ''} onClick={() => onSave({ stage: ALL_STEPS[index] })}>
                  {t(`COMMON:DEALS_STATUSES_${step.toUpperCase()}`)}
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

ProcessStep.propTypes = {
  stage: Proptypes.string,
  onSave: Proptypes.func.isRequired,
};

export default ProcessStep;
