import React from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useAuth } from '../Auth';
import Autosuggest from '../Autosuggest';
import companies from '../../lib/fetch/companies';

function CompaniesAutosuggest({ onChange }) {
  const [t] = useTranslation('COMMON');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const onFetch = async (search) => {
    const { error, data } = await companies({ search, token });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    return data;
  };

  return (
    <Autosuggest
      placeholder={t('COMMON:SEARCH_COMPANY_PLACEHOLDER')}
      fieldName="name"
      onFetch={onFetch}
      onSelect={onChange}
      getSuggestionValue={({ name = '-' } = {}) => name}
      renderSuggestion={({ name = '-' } = {}) => <span>{name}</span>}
    />
  );
}

CompaniesAutosuggest.propTypes = {
  onChange: Proptypes.func.isRequired,
};

export default CompaniesAutosuggest;
