import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { fetchActivitiesTodo, setActivityDone } from '../../lib/fetch/activities';
import { useAuth } from '../../components/Auth';
import ActivityItem from './ActivityItem';
import './ActivitiesModalToDo.scss';

const ActivitiesModalToDo = () => {
  const [t] = useTranslation('COMMON');
  const [activities = [], setActivities] = useState([]);
  const [{ token, isAuthenticated } = {}] = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      fetch(token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const fetch = async (token) => {
    const { error, data } = await fetchActivitiesTodo({ token, order: 'date', sort: 'desc' });

    if (error) {
      setActivities([]);
      return;
    }
    setActivities(data);
  };

  const setDone = async (id) => {
    const { error } = await setActivityDone({ token, id, done: 1 });

    if (error) {
      return;
    }
    dismiss(id);
  };

  const dismiss = (id) => {
    const data = (activities || []).filter((activity) => activity.id !== id);
    setActivities(data);
  };

  return (
    <>
      <Modal
        isOpen={activities && activities.length > 0}
        backdrop="static"
        keyboard={false}
        centered={false}
        zIndex="10000"
        scrollable={true}
        fade={false}
        className="custom-modal-padding"
      >
        <ModalHeader>
          {t(`COMMON:CHECK_ACTIVITIES`)}
        </ModalHeader>
        <ModalBody>
          {
            activities.map((activity, key) => (
              <ActivityItem
                setDone={setDone}
                dismiss={dismiss}
                activity={activity}
                key={key}
              />
            ))
          }
        </ModalBody>
      </Modal>
    </>
  );
};


export default ActivitiesModalToDo;
