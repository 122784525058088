import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label, CustomInput } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import DateInput from '../DateInput';
import { COST_CENTERS } from '../CostCenters/constants';
import { ALL_STEPS } from '../ProcessStep/steps';
const formatDate = date => moment(date).format('YYYY-MM-DD');

function Filters({ query = {}, onFilter } = {}) {
  const [t] = useTranslation();
  const [debouncedOnFilter] = useDebouncedCallback(value => onFilter(value), 800);
  const { from, to, search, stage, costCenter } = query;

  return (
    <Form inline className="filter" onSubmit={(event) => event.preventDefault()}>
      <FormGroup>
        <Label for="exampleSelect">{t('COMMON:FILTER_BY')}</Label>
        <Input
          type="text"
          name="search"
          id="search"
          className="mx-sm-4"
          onChange={ev => debouncedOnFilter({ search: ev.target.value, offset: 0 })}
          defaultValue={search}
          placeholder="Cerca..."
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('COMMON:COST_CENTER')}</Label>
        <CustomInput
          type="select"
          name="costCenter"
          id="costCenter"
          className="mx-sm-4"
          onChange={ev => debouncedOnFilter({ costCenter: ev.target.value, offset: 0 })}
          defaultValue={costCenter}
        >
          <option></option>
          {
            COST_CENTERS.map(({ name }) => {
              return <option value={name} key={name}>{t(`COST_CENTERS:${name}`)}</option>
            })
          }
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Label>{t('COMMON:STATE')}</Label>
        <CustomInput
          type="select"
          name="stage"
          id="stage"
          className="mx-sm-4"
          onChange={ev => debouncedOnFilter({ stage: ev.target.value, offset: 0 })}
          defaultValue={stage}
        >
          <option></option>
          {
            ALL_STEPS.map(step => {
              return <option value={step} key={step}>{t(`COMMON:DEALS_STATUSES_${step.toUpperCase()}`)}</option>
            })
          }
        </CustomInput>
      </FormGroup>
      <FormGroup>
        <Label for="Date">{t('COMMON:FROM')}</Label>
        <DateInput
          onChange={date => onFilter({ from: date && formatDate(date), offset: 0 })}
          disablePopup={true}
          value={from}
          className="mx-sm-4"
          name="from"
        />
      </FormGroup>
      <FormGroup >
        <Label for="Time">{t('COMMON:TO')}</Label>
        <DateInput
          onChange={date => onFilter({ to: date && formatDate(date), offset: 0 })}
          disablePopup={true}
          value={to}
          className="mx-sm-4"
          name="to"
        />
      </FormGroup>
    </Form>
  );
}

Filters.propTypes = {
  query: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

export default Filters;
