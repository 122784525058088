import moment from 'moment';

export const formatDate = date => date ? moment(date).format('DD-MM-YYYY') : '';

const { Intl } = window || {};
let locale;

if (Intl && typeof Intl === 'object') {
  const { language } = navigator || {};
  locale = language;
}

export const formatCurrency = (amount = 0) => {
  if (!locale) return amount;
  return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(amount);
};
