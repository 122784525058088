import React from 'react';
import { useTranslation } from 'react-i18next';
import {  Badge} from 'reactstrap';

function ActivityType({ type }) {
  const [t] = useTranslation();
  return (
    <Badge className={`btn-${type}`}>
      {t(`COMMON:${type.toUpperCase()}_LOG`)}
    </Badge>
  );
}

export default ActivityType;
