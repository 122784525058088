import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import CostCenter from './CostCenter';
import { COST_CENTERS } from './constants';
import './CostCenters.scss'

const CostCentersPresentational = ({ originalCostCenters, costCenters, onDelete, onSave, onSubmit, onCreate, error, reset }) => {
  const [t] = useTranslation('COST_CENTERS');
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  return (
    <>
      <div className="row">
        <div className="col-6">
          <p>{t('COST_CENTERS')}:</p>
        </div>
        <div className="col-6 text-right">
          <Button color="secondary" className="btn-add-attachment" onClick={() => { reset(); setOpen(true) }}>
            {t('MODIFY_COST_CENTERS')}
          </Button>
        </div>
      </div>
      {
        !originalCostCenters || originalCostCenters.length === 0 ?
          <div>{t('NO_COST_CENTERS')}</div>
          :
          originalCostCenters.map(({ name, percentage }, key) => (
            <p key={key}>
              {t(name)} - {percentage}%
            </p>
          ))
      }

      <Modal isOpen={open} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('COST_CENTERS')}
        </ModalHeader>
        <ModalBody>
          {
            COST_CENTERS.map((center, key) => {
              const c = Array.isArray(costCenters) && costCenters.find(({ name }) => name === center.name)
              return (
                <CostCenter
                  key={key}
                  color={center.color}
                  centerName={center.name}
                  percentage={c ? c.percentage : 0}
                  added={!!c}
                  onDelete={onDelete}
                  onSave={onSave}
                  onCreate={onCreate}
                />
              )
            })
          }
          {
            error && <p className="error-message">{error}</p>
          }
        </ModalBody>
        <ModalFooter className="cont-btn-modalform">
          <Button
            disabled={!!error}
            color="primary"
            onClick={() => onSubmit().then(toggle)}
          >{t('COMMON:SAVE')}</Button>
          <Button color="secondary" onClick={toggle}>{t('COMMON:CLOSE')}</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CostCentersPresentational;