import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactsPresentational from './ContactsPresentational';
import { fetchContacts, createContact } from '../../lib/fetch/contacts';
import { useAuth } from '../../components/Auth';
import { useAlert } from 'react-alert';
import { StringParam, useQueryParams } from 'use-query-params';
import './Contacts.scss';

function Contacts(props) {
  const { history } = props;
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [contactsData, setContactsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('CONTACTS');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    search: StringParam,
  });

  const { search } = query;

  useEffect(() => {
    const fetch = async (query) => {
      setIsLoading(true);
      const { error, data, headers } = await fetchContacts(query);

      if (error) {
        setIsLoading(false);
        setContactsData([]);
        setTotal(0);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setIsLoading(false);
      setContactsData(data);
      setTotal(Number(headers['x-total-count']));
    };

    fetch({ token, search, limit: 100000 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onCreateContact = async () => {
    const defaultContact = {
      first_name: t('COMMON:NEW_CONTACT_FIRSTNAME'),
      last_name: t('COMMON:NEW_CONTACT_LASTNAME'),
    };

    setIsAdding(true);
    const { error, data } = await createContact({ data: defaultContact, token });
    setIsAdding(false);

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push(`/contact-detail/${data.id}`);
  };

  return (
    <ContactsPresentational
      contacts={contactsData}
      total={total}
      query={query}
      onCreateContact={onCreateContact}
      onFilter={filters => setQuery(filters || query)}
      isLoading={isLoading}
      isAdding={isAdding}
      {...props}
    />
  );
}

export default Contacts;
