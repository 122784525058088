import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import moment from 'moment';
import { useAuth } from '../../components/Auth';
import deals from '../../lib/fetch/deals';
import DealsPresentational from './DealsPresentational';
import createDeal from '../../lib/fetch/createDeal';
import './Deals.scss';

function Deals(props) {
  const { history } = props;
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dealsData, setDealsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('DEALS');
  const [auth = {}] = useAuth();
  const { token, profile } = auth;

  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    search: StringParam,
    from: StringParam,
    to: StringParam,
    stage: StringParam,
    costCenter: StringParam,
  });

  const { offset, search, from, to, stage, costCenter } = query;

  useEffect(() => {
    const fetchDeals = async (query) => {
      setIsLoading(true);
      const { error, data, headers } = await deals(query);

      if (error) {
        setIsLoading(false);
        setDealsData([]);
        setTotal(0);
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      setIsLoading(false);
      setDealsData(data);
      setTotal(Number(headers['x-total-count']));
    };

    fetchDeals({ token, offset, search, from, to, stage, costCenter });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, search, from, to, stage, costCenter]);

  const onCreateDeal = async () => {
    const { id: user_id } = profile || {};

    const defaultDeal = {
      name: t('COMMON:NEW_DEAL'),
      stage: 'appointment_scheduled',
      amount: 0,
      date: new Date(),
      user_id,
      closing_date: moment().add(1, 'months')
    };

    setIsAdding(true);
    const { error, data } = await createDeal({ data: defaultDeal, token });
    setIsAdding(false);

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    history.push(`/deal-detail/${data.id}`);
  }

  return (
    <DealsPresentational
      deals={dealsData}
      total={total}
      query={query}
      onFilter={setQuery}
      onCreateDeal={onCreateDeal}
      isLoading={isLoading}
      isAdding={isAdding}
      {...props}
    />
  );
}

export default Deals;
