import axios from 'axios';

const save = async ({ token, data = {} } = {}) => {
  const { id } = data;

  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/deals/${id}`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default save;
