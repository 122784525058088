import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './FrozenButton.scss';

function FrozenButton({ frozen = 0, onSave }) {
  const [t] = useTranslation('COMMON');

  return (
    <p className={`freezed ${frozen ? 'freezed-active' : ''}`}>
      <span>{frozen ? t('FREEZED') : ''}</span> <Button onClick={() => onSave({ frozen: frozen ? 0 : 1 })}><i className="far fa-snowflake" /></Button> 
    </p>
  );
}

FrozenButton.propTypes = {
  frozen: PropTypes.number,
  onSave: PropTypes.func.isRequired,
};

export default FrozenButton;
