import axios from 'axios';

const login = async (email, password) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_URL}/login?appId=${process.env.REACT_APP_ID}`,
      data: {
        email,
        password,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default login;
