import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import CloseDetector from './../CloseDetector';
import './EditableTextarea.scss';

function EditableTextarea({ value, onSave, allowEmpty = false }) {
  const [editable, setEditable] = useState(false);
  const [taValue, setTaValue] = useState('');

  useEffect(() => setTaValue(value), [value]);

  const onKeyDown = (e) => {
    if (e.keyCode === 27) { // ESC
      onClose({ toSave: false });
      setTaValue(value);
    }
  };

  const onClose = ({ toSave = false }) => {
    if (editable && toSave && typeof onSave === 'function') {
      onSave(allowEmpty ? taValue : (taValue || value));
    }

    setEditable(false);
  };

  return (
    <CloseDetector onClose={onClose}>
      <Input
        type="textarea"
        className={`editable-textarea${editable ? ' editable' : ''}`}
        onChange={ev => setTaValue(ev.target.value)}
        onKeyDown={onKeyDown}
        value={taValue}
        onFocus={() => setEditable(true)}
      />
    </CloseDetector>
  );
}

EditableTextarea.propTypes = {
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  allowEmpty: PropTypes.bool,
};

export default EditableTextarea;
