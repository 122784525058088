const initialState = {
  isAuthenticated: false,
  appLoaded: false,
  profile: {},
  token: null,
};

function authReducer(state, action = {}) {
  const { type, token, profile } = action;

  switch (type) {
    case 'logout':
      return { ...initialState };
    case 'login':
      return { ...state, isAuthenticated: true, token };
    case 'setProfile':
      return { ...state, appLoaded: true, profile };
    default:
      return { ...state };
  }
}

export { initialState, authReducer };
