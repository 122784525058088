import React from 'react';
import { Switch } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const RoutesWrapper = ({ publicRedirect, privateRedirect, children }) => {
  const [{ isAuthenticated } = {}] = useAuth();

  const childrenComponents = React.Children.map(children, child => {
    return React.cloneElement(child, {
      isAuthenticated,
      publicRedirect,
      privateRedirect,
    });
  });

  return (
    <Switch>
      {childrenComponents}
    </Switch>
  );
};

export default RoutesWrapper;
