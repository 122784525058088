const INVALID_TOKEN_MESSAGE = 'Invalid token.';

export const formatError = (error, t) => {
  const { response = {} } = error;
  const { status, data = {} } = response;
  const { message } = data;
  let errorKey = 'ERROR';
  if (status === 400) {
    errorKey = message === INVALID_TOKEN_MESSAGE ? 'INVALID_CODE' : 'INVALID_PASSWORD';
  }
  return t(errorKey);
}
