import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import './Pagination.scss';

function PaginationCustom({ total = 0, offset = 0, limit: limitValue, onFilter }) {
  const REACT_APP_DEFAULT_PAGINATION_LIMIT = parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 10);

  const limit = limitValue || REACT_APP_DEFAULT_PAGINATION_LIMIT;
  const pages = Math.ceil(total / limit);

  const setOffset = (offset = 0) => onFilter({ offset });

  if (total <= REACT_APP_DEFAULT_PAGINATION_LIMIT) {
    return null
  }

  return (
    <div className="cont-pagination">
      <Pagination>
        <PaginationItem disabled={parseInt(offset) === 0}>
          <PaginationLink first onClick={() => setOffset(0)} />
        </PaginationItem>
        <PaginationItem disabled={parseInt(offset) === 0}>
          <PaginationLink previous onClick={() => setOffset(offset - limit)} />
        </PaginationItem>
        {
          Array(pages).fill(null).map((n, i) => {
            const page = i + 1
            const currentPage = (offset / limit) + 1
            return (
              <PaginationItem key={page} active={page === currentPage}>
                <PaginationLink onClick={() => setOffset(i * limit)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            )
          })
        }
        <PaginationItem disabled={(offset + limit) >= total}>
          <PaginationLink next onClick={() => setOffset(offset + limit)} />
        </PaginationItem>
        <PaginationItem disabled={(offset + limit) >= total}>
          <PaginationLink last onClick={() => setOffset((pages - 1) * limit)} />
        </PaginationItem>
      </Pagination>
    </div>
  )
}

PaginationCustom.propTypes = {
  total: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  onFilter: PropTypes.func.isRequired,
};

export default PaginationCustom;
