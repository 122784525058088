import React from 'react';
import ActivitiesBadge from '../ActivitiesBadge';
import './RubricActivitiesBadge.scss';

export default (props) => {
  return (
    <>
      <ActivitiesBadge>{props.children}</ActivitiesBadge>
      <i className="fas fa-chevron-right" />
    </>
  );
};
