import React from 'react';
import Table from './Table';
import Pagination from './Pagination';

function DataTable(props) {
  return (
    <>
      <Table {...props} />
      <Pagination {...props} />
    </>
  );
}

export default DataTable;
