import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useAuth } from '../../components/Auth';
import ActivitiesPresentational from './ActivitiesPresentational';
import { fetchActivities, saveActivity, deleteActivity } from '../../lib/fetch/activities';
import { prepareActivity } from '../../lib/helpers/activities';
import { useLoading } from '../../components/Loading';

function Activities(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation();
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading()

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });

  const { offset } = query;

  useEffect(() => {
    fetch({ token, offset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const fetch = async (query) => {
    setIsLoading(true);
    const { error, data, headers } = await fetchActivities({ ...query, order: 'date', sort: 'desc' });

    if (error) {
      setIsLoading(false);
      setActivities([]);
      setTotal(0);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setIsLoading(false);
    setActivities(data);
    setTotal(Number(headers['x-total-count']));
  };

  const onSaveActivity = async ({ id, values, type }) => {
    const data = prepareActivity({ activity: values, type })
    const { error } = await saveActivity({ token, id, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    alert.success(t('COMMON:SAVE_SUCCESS'));
    fetch({ token })
  }

  const onDeleteActivity = async (id) => {
    showLoading()
    const { error } = await deleteActivity({ token, id });
    hideLoading()

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }
    alert.success(t('COMMON:DELETE_SUCCESS'));
    fetch({ token })
  }

  return (
    <ActivitiesPresentational
      activities={activities}
      total={total}
      query={query}
      onFilter={setQuery}
      isLoading={isLoading}
      onSaveActivity={onSaveActivity}
      onDeleteActivity={onDeleteActivity}
      {...props}
    />
  );
}

export default Activities;
