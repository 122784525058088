import axios from 'axios';
import qs from 'qs';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT: defaultLimit } = process.env;

const fetchCompanies = async (params = {}) => {
  const {
    token,
    order = 'name',
    sort = 'asc',
    offset = 0,
    limit = defaultLimit,
    search,
    from,
    to,
  } = params;

  const queryString = { order, sort, offset, limit, search, from, to };

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies?${qs.stringify(queryString)}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fetchCompanies;
