import React from 'react';
import verification from '../../lib/fetch/verification';
import VerificationPresentational from './VerificationPresentational';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { formatError } from './formatError';

function Verification(props) {
  const { t } = useTranslation();

  const alert = useAlert();

  const onVerify = async ({ code = '' }) => {
    const { error } = await verification(code);

    if (error) {
      alert.error(formatError(error, t));
      return;
    }

    if (props.history) {
      props.history.push('/')
    }
  }

  return (<VerificationPresentational onVerify={onVerify} {...props} />);
}

export default Verification;
