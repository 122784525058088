import axios from 'axios';

export const fetchCompany = async ({ token, id } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const createCompany = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/companies`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const saveCompany = async ({ id, token, data = {} } = {}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteCompany = async ({ id, token } = {}) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/companies/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response }
    }

    return response;

  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
