import React, { Fragment, cloneElement } from 'react';
import { Table } from 'reactstrap';
import { getGroupInitial, groupByKeyword, stripAdmins } from '../../lib/helpers/groupByNameInitial';
import get from 'lodash.get';
import './RubricTable.scss';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

const getEmptyRowWithText = (text = '') => (
  <div className="no-activities">
    {text}
  </div>
);

const getValue = (source = '', data = {}) => {
  if (!source || typeof source !== 'string' || typeof data !== 'object') {
    return;
  }

  const sources = source.split('+') || [];

  if (sources.length > 1) {
    return sources.map(s => get(data, s, '')).join(' ');
  }

  return get(data, source, '');
}

function RubricTable({ children = [], items = [], isLoading, keyword = 'name', onItemClick }) {
  const [t] = useTranslation();
  if (isLoading) return getEmptyRowWithText(t('COMMON:LOADING'));
  if (!items || !items.length) return getEmptyRowWithText(t('COMMON:NO_DATA'));

  const itemsWithoutAdmins = stripAdmins(items);
  const groups = groupByKeyword(itemsWithoutAdmins, keyword);

  return (
    <Table responsive hover className="rubric-table">
      {
        groups.map((group = [], groupIndex) => (
          <Fragment key={groupIndex}>
            <thead>
              <tr>
                <th colSpan={children.length + 1}>{getGroupInitial(group, keyword)}</th>
              </tr>
            </thead>
            <tbody>
              {
                group.map((item = {}, key) => {
                  return (
                    <tr key={key} onClick={() => typeof onItemClick === 'function' && onItemClick(item)}>
                      {
                        children.map((child, key) => {
                          const { props = {} } = child;
                          const { source = {} } = props;
                          return cloneElement(child, { ...props, key, value: getValue(source, item) });
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Fragment>
        ))
      }
    </Table>
  );
}

RubricTable.propTypes = {
  items: Proptypes.array,
  isLoading: Proptypes.bool,
  keyword: Proptypes.string, // key for group by (es: "name" -> group by name)
  onItemClick: Proptypes.func,
};

export default RubricTable;
