import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CostCenterPresentational from './CostCentersPresentational';
import { useAuth } from '../Auth'
import { useAlert } from 'react-alert';
import { dealCostCenters } from '../../lib/fetch/costCenters';
import clonedeep from 'lodash.clonedeep';

const CostCenters = ({ costCenters, dealId, onSuccessCallback }) => {
  const [t] = useTranslation('COST_CENTERS');
  const [{ token } = {}] = useAuth();
  const [error, setError] = useState('');
  const [centers, setCenters] = useState([]);
  const alert = useAlert();

  const reset = useCallback(() => {
    setCenters(clonedeep(costCenters))
    setError('')
  }, [costCenters])

  useEffect(() => {
    reset()
  }, [costCenters, reset])


  const onCreate = async (name, color) => {
    const center = {
      name,
      percentage: (!centers || centers.length === 0) ? 100 : 0,
      color
    }
    const newCenters = (centers || []).concat([center])
    refreshCenters(newCenters)
  }

  const onSave = async (name, percentage, color) => {
    const newCenters = centers.map(center => {
      if (center.name === name) {
        center.percentage = percentage
        center.color = color
      }
      return center
    })
    refreshCenters(newCenters)
  }

  const onDelete = (name) => {
    const newCenters = (centers || []).filter(({ name: n }) => name !== n)
    refreshCenters(newCenters)
  }


  const onSubmit = async () => {
    if (!validate(centers)) {
      return
    }

    const data = centers.map(({ color, percentage, name }) => ({
      name,
      color,
      percentage: Number(percentage)
    }))

    const { error } = await dealCostCenters({ dealId, token, data })

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    alert.success(t('COMMON:SAVE_SUCCESS'));
    onSuccessCallback();
  }

  const refreshCenters = (newCenters) => {
    setCenters(newCenters)
    validate(newCenters)
  }

  const validate = (centers) => {
    const sum = centers.map(c => c.percentage).reduce((p1, p2) => Number(p1) + Number(p2), 0)
    const isValid = centers.length === 0 || sum === 100
    setError(isValid ? '' : t('SUBMIT_PERCENTAGE_ERROR'))
    return isValid
  }

  return (
    <CostCenterPresentational
      originalCostCenters={costCenters}
      costCenters={centers}
      onDelete={onDelete}
      onCreate={onCreate}
      onSave={onSave}
      onSubmit={onSubmit}
      error={error}
      reset={reset}
    />
  )
}

export default CostCenters;