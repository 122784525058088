import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInput, Input, FormText, InputGroup, InputGroupAddon, InputGroupText, } from 'reactstrap';

const CostCenter = ({ centerName, percentage, color, added, onDelete, onSave, onCreate }) => {

  const [t] = useTranslation('COST_CENTERS');
  const [error, setError] = useState('')

  const onChangePerc = (event) => {
    const { value } = event.target
    onSave(centerName, value, color)
    validatePerc(value)
  }

  const validatePerc = (percentage) => {
    const p = Number(percentage)
    if (p < 0 || p > 100) {
      setError(t('INPUT_PERCENTAGE_ERROR'))
      return
    }
    setError('')
  }

  return (
    <div className="cost-center-input">
      <CustomInput
        className="center-checkbox"
        inline={true}
        checked={added}
        type="checkbox"
        id={centerName}
        label={t(centerName)}
        onChange={(e) => e.target.checked ? onCreate(centerName, color) : onDelete(centerName)}
      />
      {
        added &&
        <div style={{ display: 'inline-grid' }}>
          <InputGroup>
            <Input
              name={centerName}
              type="number"
              value={percentage}
              onChange={onChangePerc}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText style={{ backgroundColor: 'white' }}>%</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {
            error &&
            <FormText className="error-message">{error}</FormText>
          }
        </div>
      }
    </div>
  )
}

export default CostCenter;